.wrap {
  font-weight: 600;

  > span {
    color: var(--brand);
  }
}

.h1 {
  font-size: 22px;
}
.h2 {
  font-size: 20px;
}
.h3 {
  font-size: 18px;
}
.h4 {
  font-size: 16px;
}
.h5 {
  font-size: 14px;
}
.h6 {
  font-size: 12px;
}

.xs {
  margin: 0 0 4px;
}
.s {
  margin: 0 0 8px;
}
.m {
  margin: 0 0 16px;
}
.l {
  margin: 0 0 24px;
}
.xl {
  margin: 0 0 32px;
}
.xxl {
  margin: 0 0 48px;
}

.black {
  color: var(--black);
}

.primary {
  color: var(--brand);
}

.white {
  color: var(--white);
}

.center {
  text-align: center;
}
