.wrap {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  border: 1px solid;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  will-change: color, background-color, border-color;

  > * + * {
    margin-left: 4px;
  }

  svg path {
    transition: fill 0.3s ease;
  }

  p {
    position: relative;
    font-size: 12px;
    white-space: nowrap;
  }

  &:hover {
    color: var(--white);
    background-color: var(--brand);
    border-color: var(--brand);

    svg path {
      fill: var(--white);
    }

    &::before {
      opacity: 0.1;
    }
  }

  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.sm-square {
  min-width: 30px;
  height: 30px;
  padding: 2px;
  font-size: 10px;
}

.lg-square {
  min-width: 40px;
  height: 40px;
  padding: 4px;
  font-size: 12px;
}

.sm {
  height: 28px;
  padding: 0 8px;
  font-size: 10px;
}

.lg {
  height: 40px;
  padding: 16px 24px;
  font-size: 12px;
}

.white {
  background-color: var(--white);
  color: var(--brand);
  border-color: var(--white);

  svg path {
    fill: var(--brand);
  }
}

.light {
  background-color: rgba(0, 0, 0, 0);
  border-color: var(--brand);
  color: var(--brand);

  svg path {
    fill: var(--brand);
  }
}

.primary {
  background-color: var(--brand);
  border-color: var(--brand);
  color: var(--white);

  svg path {
    fill: var(--white);
  }

  &::before {
    content: "";
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
}

.light {
  background-color: rgba(0, 0, 0, 0);
  border-color: var(--brand);
  color: var(--brand);

  svg path {
    fill: var(--brand);
  }
}
