.wrap {
  position: relative;
  display: block;
  width: calc(100% + 60px);
  margin-left: -30px !important;
  margin-right: -30px !important;

  > div {
    width: 100%;
    min-height: 500px;
    padding: 0 0 30px;
    overflow-x: scroll;
  }

  &::before {
    content: "";
    z-index: 1;
    position: absolute;
    top: 0;
    bottom: 10px;
    left: 0;
    width: 30px;
    background: linear-gradient(
      270deg,
      var(--white-rgba2) 0%,
      var(--white-rgba) 50%,
      var(--white-rgba) 100%
    );
  }

  &::after {
    content: "";
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 10px;
    width: 30px;
    background: linear-gradient(
      270deg,
      var(--white-rgba) 0%,
      var(--white-rgba) 50%,
      var(--white-rgba2) 100%
    );
  }
}

.content {
  width: 1340px;

  > div {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    margin: 0 24px 0 0;
    width: 300px;

    > * {
      width: 100%;
    }

    &:first-child {
      margin: 0 20px 0 30px;
    }

    &:last-child {
      margin: 0 30px 0 0;
    }
  }
}
