.wrap {
  display: flex;

  > span {
    display: flex;
  }

  > span + span::before {
    display: inline-block;
    margin-right: 5px;
    content: ", ";
  }
}
